body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
}
.container{
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
}
.container_top{
    width: 100%;
    height: 20%;
    background-color: #F1F2F5;
    padding: 44px 50px 40px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}
.top_left1{
    width: 103px;
    height: 32px;
    padding:5px 4px;
}
.top_left1:hover{
    width: 103px;
    height: 30px;
    background-color: #DADBDF;
    border-radius: 4px;
}
.top_left_img{
    width: 17px;
    height: 18px;
    margin-top: -4px;
}
.top_left{
    display: inline-block;
    font-size: 16px;
    color: #00284D;
    margin-left: 10px;
    line-height: 18px;
}
.container_bottom{
    width: 100%;
    height: 80%;
    background-color: #fff;
    position: relative;
}
.bottom_box{
    width: 630px;
    position: absolute;
    top:40px;
    left: 0;
    right: 0;
    margin: auto;
}
.pre_line{
    width: 630px;
    height: 30px;
    margin-bottom: 30px;
}
.pre_one{
    width: 200px;
    font-size: 16px;
    color: #666;
    float: left;
}
.pre_two{
    width: 200px;
    font-size: 16px;
    color: #333;
    float: left;
}
.pre_img{
    width: 16px;
    height: 24px;
    float: right;
}
.head_img_box{
    position: absolute;
    top: 90px;
    bottom: 40px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 90px;
    height: 90px;
    border-radius: 90px;
    overflow: hidden;
}
.head_img{
    width: 90px;
    height: 90px;
    border-radius: 90px;
}
.change_head_img{
    width: 90px;
    height: 28px;
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    color: #fff;
    font-size: 12px;
    line-height: 28px;
    opacity: 0;
}
.update_nickname{
    outline: none;
    border:1px solid #bcbcbc;
    padding: 10px;
    width: 332px;
    font-size: 16px;
    color: #333;
}
.update_box{
    margin-left: 36%;
}
.user_sex{
    width: 40px;
    float: left;
    margin-right: 62px;
}
.user_sex input{
    margin-right: 8px;
}
.upload_text{
    width: 90px;
    height: 28px;
    background-color:rgba(0,0,0,0.2);
    position: absolute;
    bottom:0;
    text-align: center;
    color: #fff;
    font-size: 12px;
}
