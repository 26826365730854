body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
}
*{
  box-sizing: border-box;
}
.container{
  width: 100vw;
  height:100vh;
  background-color: #F1F2F5!important;
  position: relative;
}
.left{
  width: 252px;
  height: 100%;
  background-color: #00152B;
  padding-top: 20px;
  box-sizing: border-box;
  position: relative;
  float: left;
}
.kf{
  height:64px;
  background-color: #00284D;
  padding: 7px 18px;
}
.ke_img{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  float: left;
  border-radius: 50px;
}
.kf_info{
  float: left;
}
.nick_name{
  font-size: 15px;
  color:#fff;
  line-height: 22px;
}
.job_num{
  font-size: 12px;
  color: #8498AD;
  line-height: 22px;
  margin-top: 3px;
}
.search{
  height: 46px;
  padding: 8px 12px;
  background-color: #00152B;
  border-bottom: 1px solid #022B58;
  position: relative;
}
.search_btn{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 13px;
  left: 17px;
  z-index: 99;
}
.search_box{
  width: 228px;
  height: 30px;
  padding-left: 33px;
  background-color: #00284D;
  outline: none;
  border: none;
  border-radius: 3px;
  color: #8498AD;
  font-size: 12px;
}
.item{
  height: 72px;
  border-bottom: 1px solid #023F82;
  padding: 10px 18px;
  position: relative;
  background-color: #00152B;
  line-height: 52px;
}
.item1{
  height: 72px;
  border-bottom: 1px solid #023F82;
  padding: 10px 18px;
  position: relative;
  background-color: #178FFF;
}
.delete{
  width: 14px;
  height: 14px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 2px;
  margin: auto;
  opacity: 0;
}
.item:hover{
  background-color: #0C3765;
}
.item:hover .delete{
  opacity: 1;
}
.item1:hover .delete{
  opacity: 1;
}
.item_left{
  float: left;
  position: relative;
}
/*.item img{*/
  /*width: 50px;*/
  /*height: 50px;*/
  /*border-radius: 50px;*/
  /*margin-right: 8px;*/
/*}*/
.no_read{
  width: 10px;
  height: 10px;
  background-color:#D63533 ;
  border-radius: 5px;
  position: absolute;
  right:8px;
  top:0;
}
.kh_name{
  width: 110px;
  font-size: 15px ;
  color: #fff;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  line-height: 52px;
}
.kh_time{
  width: 32px;
  height: 52px;
  overflow: hidden;
  font-size: 12px;
  color: #E9E9E9;
  float: right;
  line-height:52px;
  flex-wrap: nowrap;
}
.setting{
  width: 100%;
  height: 50px;
  background-color: #00284D;
  padding: 15px 18px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.setting img{
  width: 19px;
  height: 19px;
  margin-right: 9px;
  /*float: left;*/
  /*vertical-align: middle;*/
}
.setting_text{
  font-size: 14px;
  color: #8498AD;
  /*float: left;*/
  display: inline-block;
}
.right{
  /*width: 800px;*/
  height: 100%;
  /*float: left;*/
  background-color: #F1F2F5;
  margin-left: 252px;
}
.right_top{
  width: 100%;
  height: 64px;
  /*height: 6%;*/
  background-color: #fff;
  text-align: center;
  font-size: 15px;
  color: #232220;
  line-height: 64px;
  margin-top: 20px;
  /*margin-top: 1.8%;*/
}
.right_mid{
  width: 100%;
  height: 92.2%;
  /*padding: 1.8%;*/
  padding-top: 13px;
  padding-left: 19px;
  padding-right: 19px;
  padding-bottom: 30px;
  background-color: #F1F2F5;
}
.chat_content{
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}
.chat_content1{
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  z-index: 888;
}
.show_chat{
  width: 100%;
  height: 72%;
  border-bottom: 1px solid #D9D9D9;
  overflow-y: scroll;
  padding-bottom: 66px;
  position: relative;
}
/*隐藏滚动条*/
.show_chat::-webkit-scrollbar {
  display: none;
}
.chat_input{
  width: 100%;
  height: 28%;
  position: relative;
  /*background-color: red;*/
}
.chat_item_text{
  width: auto;
  padding: 9px 16px;
  border:1px solid #e9e9e9;
  border-radius: 6px;
  font-size: 15px;
  color: #333;
  float: left;
  margin-left: 14px;
  position: relative;
}
.chat_item_img{
  width: auto;
  border:1px solid #e9e9e9;
  border-radius: 6px;
  font-size: 15px;
  color: #333;
  float: left;
  margin-left: 14px;
  position: relative;
}
.chat_item_text1:before{
  content: '';
  border-right: 6px solid #e9e9e9;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: -6px;
  margin-top: 10px;
}
.chat_item_text1:after{
  content: '';
  border-right: 4px solid #fff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: -4px;
  margin-top: 10px;
}
.chat_item{
  width: 100%;
  float: left;
  padding-top: 14px;
  overflow: hidden;
}
.chat_item1{
  width: 100%;
  height: auto;
  /*padding: 14px 0;*/
  padding-top: 14px;
  overflow: hidden;
}
.chat_item_child{
  float: right;
}
.chat_item_text2:before{
  content: '';
  border-left: 6px solid #C9DEF3;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -6px;
  margin-top: 10px;
}
.chat_item_text2:after{
  content: '';
  border-left: 4px solid #CCE6FF;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -4px;
  margin-top: 10px;
}
.sure_price{
  width: 257px;
  height: 103px;
  border: 1px solid #E9E9E9;
  padding: 10px 16px;
  border-radius: 6px;
  margin: 0 14px;
  float: left;
  position: relative;
}
.sure_price_text{
  max-width: 250px;
  display: inline-block !important;
  display: inline;
  height: auto;
  border: 1px solid #C9DEF3;
  padding: 10px 16px;
  border-radius: 6px;
  margin: 0 14px;
  float: left;
  position: relative;
  background-color: #CCE6FF;
  word-wrap:break-word;
  text-align: left;
}
.sure_price_img{
  width: auto;
  height: auto;
  border: 1px solid #C9DEF3;
  border-radius: 6px;
  margin: 0 14px;
  float: left;
  position: relative;
}
.sure_text{
  position: absolute;
  bottom: 8px;
}
.chat_kh_img{
  width: 40px;
  height: 40px;
  border-radius: 40px;
  float: left;
}
.chat_kf_img{
  width: 40px;
  height: 40px;
  float: right;
  border-radius: 40px;
}
.ask_price{
  width: 257px;
  height: 80px;
  border: 1px solid #E9E9E9;
  padding: 10px 16px;
  border-radius: 6px;
  margin: 0 14px;
  float: left;
  position: relative;
}
.hot_img{
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 4px;
  float: left;
}
.hot_info{
  width: 151px;
  float: left;
  text-align: left;
}
.hot_name{
  font-size: 15px;
  color: #333;
  margin-bottom: 11px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.have_price{
  font-weight: 700;
  font-size: 14px;
  color: #D6AE60;
}
.have_discount_name{
  font-size: 15px;
  color: #333;
  margin-bottom: 1px;
}
.discount_text{
  font-size: 12px;
  color: #A7A7A7;
  margin-bottom: 1px;
}
.now_price{
  font-size: 14px;
  font-weight: 700;
  color:#D6AE60 ;
  margin-right: 6px;
  float: left;
}
.old_price{
  font-size: 12px;
  color: #A7A7A7;
  text-decoration: line-through;
  float: left;
  margin-top: 3px;
}
.set_price_btn{
  width: 76px;
  height: 28px;
  border: 1px solid #118EE9;
  font-size: 14px;
  color: #118EE9;
  text-align: center;
  line-height: 28px;
  border-radius: 2px;
}

/*弹出框的样式*/
.price_box{
  overflow: hidden;
  width: 72%;
  border: 1px solid #BCBCBC;
  font-size: 16px ;
  color: #333;
  margin: 0 auto;
  padding: 10px 11px;
}
.price_box input{
  outline: none;
  border: none;
  float: left;
}
.price_box input::-webkit-outer-spin-button,
.price_box input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.price_box input[type="number"] {
  -moz-appearance: textfield;
}
.contacts{
  width: 100%;
  height: 83.3%;
  padding-bottom: 4.6%;
  overflow-y: scroll;
}
.contacts::-webkit-scrollbar {
  display: none;
}
.replay{
  width: 100%;
  height: 100%;
  vertical-align:top;
  color: #999;
  font-size: 14px;
  line-height: 20px;
  resize: none;
  outline: none;
  border:none;
}
.expression{
  height: 50%;
  border-radius: 100%;
  margin-right:0.9%;
  margin-top: 10px;
  float: left;
}
.picture{
  height: 50%;
  margin-top: 10px;
  float: left;
}
.send_btn{
  position: absolute;
  right: 0px;
  bottom: 7%;
}
.ku_chat_img{
  /*max-width: 240px;*/
  height: 240px;
  border-radius: 6px;
}
.search_result{
  position: absolute;
  top: 129px;
  left: 0;
  width: 252px;
  height: 570px;
  background-color: #fff;
  box-shadow:0 2px 8px 0 rgba(0,0,0,0.5);
  /*padding-left: 18px;*/
  z-index: 100;
  overflow-y: scroll;
}
/*滚动条样式*/
.search_result::-webkit-scrollbar {
  width: 6px;
}
.search_result::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #D4D5D6;
}
.search_result::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #F1F2F5;
}
.search_result_title{
  font-size: 12px;
  color: #888;
  padding: 8px 0;
  border-bottom:1px solid rgba(238,238,238,1);
  margin-left: 18px;
}
.search_item{
  width: 100%;
  height: 72px;
  border-bottom:1px solid #eee;
}
.search_item:hover{
  background-color: #EBEDF0;
}
.search_img{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 8px;
  float: left;
  margin-top: 10px;
  margin-left: 18px;
}
.search_text{
  font-size: 15px;
  color: #333;
  float: left;
  line-height: 70px;
}
.search_text1{
  font-size: 15px;
  color: #118EE9;
  float: left;
  line-height: 70px;
}
.type10{
  width: 200px;
  height: 20px;
  background:rgba(53,53,56,0.3);
  border-radius:3px;
  margin: 0px auto;
  color:rgba(255,255,255,0.7);
  font-size: 12px;
  text-align: center;
}
.no_result{
  text-align: center;
  padding-top: 50px;
  color: #999999;
}
.picture1{
  position: absolute;
  height: 50%;
  top: 10px;
  opacity: 0;
  width: 20px;
}
.loadMore{
  text-align: center;
}
.selected_img_box{
  width: 300px;
  height: 300px;
  background-color: red;
}
.selected_img {
  width: 240px;
  height: 240px;
}
#replay{
  width: 100%;
  height: 50%;
  position: relative;
  padding: 6px 10px;
  border:1px solid #D9D9D9;
  border-radius: 4px;
  overflow:hidden;
  color:#333;
  font-weight: 400;
}
#replay::-webkit-input-placeholder{color:#999;}
#replay img{
  width: 240px;
  height: 240px;
  position: absolute;
  top: 6px;
  left: 10px;
}
.morenews{
  text-align: center;
  margin: 10px 0;
}
.kh_img_box{
  width: 240px;
  height: 240px;
  margin: 0 auto;
  overflow-x: hidden;
  border-radius: 6px;
}
.quick_deletion{
  width: 16px;
  height: 16px;
  background-color: #51779B;
  border-radius: 16px ;
  position: absolute;
  top: 15px;
  right: 18px;
}
.show_time{
  width:auto;
  line-height:20px;
  background:rgba(53,53,56,0.3);
  border-radius:3px;
  padding: 0 6px;
  color: #fff;
  font-size: 12px;
  margin: 14px auto;
  text-align: center;
  display: inline-block;
  /*margin-left: 50%;*/
}
.container_box{
  width: 100%;
  height: auto;
  text-align: center;
}
.send_btn1{
  position: absolute;
  right: 40px;
  bottom: 40px;
}

/*编辑器*/
.toolbar {
  border: 1px solid #ccc;
}
.text {
  border: 1px solid #ccc;
  width: 100%;
  height: 18%;
  position: relative;
}
#div2::-webkit-scrollbar {
  display: none;
}
.no_more{
  margin-left: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
}

