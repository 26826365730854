body{
    width: 100%;
    height: 100%;
}
.container{
    background-color: #fff;
}
.login_top{
    width: 100%;
    height: 100px;
    background-color: #fff;
    padding-left: 360px;
    /*margin-bottom: 185px;*/
}
.log_title{
    font-size: 24px;
    color: #333;
    line-height: 100px;
    float: left;
    font-weight: 500;
}
.line{
    width: 1px;
    height: 18px;
    background-color: #CBCBCB;
    float: left;
    margin: 44px 14px 0 12px;
}
.log_btn{
    height: 100px;
    font-size: 16px;
    color: #2C3B47;
    float: left;
    font-weight:400;
    line-height: 105px;
}
.log_bottom{
    width: 480px;
    height: 510px;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    margin: auto;
    background-color: #fff;
    padding: 60px 65px 118px 65px;
    box-shadow:0px 2px 20px 0px rgba(216,224,230,1);
    border-radius:6px;
}
.title{
    font-size: 30px;
    color: #178FFF;
    margin-bottom: 20px;
    font-weight: 600;
    text-align: center;
}
.log_phone,.log_yzm{
    width: 348px;
    height:30px;
    border-bottom: 1px solid #EFEFEF;
    background-color: #fff;
    margin-top: 50px;
}
.log_label{
    width: 60px;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin-right: 20px;
    float: left;
}
.log_phone input,.log_yzm input{
    outline: none;
    border:none;
    font-weight: 500;
    color: #333;
}

.log_phone input::-webkit-outer-spin-button,
.log_phone input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.log_phone input[type="number"] {
    -moz-appearance: textfield;
}
.log_phone input::-webkit-input-placeholder{color:#999;}
.log_yzm input::-webkit-outer-spin-button,
.log_yzm input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.log_yzm input[type="number"] {
    -moz-appearance: textfield;
}
.log_yzm input::-webkit-input-placeholder{color:#999;}
.log_yzm{
    position: relative;
}
.yzm{
    position: absolute;
    bottom: 10px;
    font-size: 14px;
    color: #178FFF;
    right: 0;
    font-weight: 500;
}
.log_button{
    width: 348px;
    height: 46px;
    background-color: #178FFF;
    border-radius: 2px;
    text-align: center;
    line-height: 46px;
    margin-top: 60px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}
.log_button:hover{
    background-color: #178FFF;color: #fff;
}